import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { SocialChannel } from 'src/app/social-media/models';
import { ContentBlock } from '../../models';
import { Image } from '@teamfoster/sdk/image';

@Component({
  selector: 'app-content-block-payoff',
  templateUrl: './content-block-payoff.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockPayoffComponent implements OnInit {
  @Input({ required: true }) block!: ContentBlock;
  @Input() cookiePreferences!: CookiePreferences;
  @Input() index!: number;
  @Input() socialChannels: SocialChannel[] | null = [];

  placeholderImages: Image[] = [
    {
      alt: 'Jongen met duim omhoog',
      url: 'https://100100100.fstr.io/media/uploads/mark-fuller-IsNx-bVQHIE-unsplash.jpg',
    },
    {
      alt: 'Bananenschil op de vloer',
      url: 'https://100100100.fstr.io/media/uploads/louis-hansel-RAkQAMrjOvk-unsplash.jpg',
    },
    {
      alt: 'Meisje gooit afval weg',
      url: 'https://100100100.fstr.io/media/uploads/Afval2.jpeg',
    },
    {
      alt: 'Vrouw gooit afval weg',
      url: 'https://100100100.fstr.io/media/uploads/Afval1(1).jpeg',
    },
    {
      alt: 'Schoonmaakmiddelen op een aanrecht',
      url: 'https://goes.100-100-100.nl/media/uploads/schoonmaakmiddel.jpg',
    },
    {
      alt: 'Fotocameras krijgen een tweede leven',
      url: 'https://100100100.fstr.io/media/uploads/antonio-scant-NrcF80ZC8EI-unsplash.jpg',
    },
  ];

  sixImages: Image[] = [];

  ngOnInit(): void {
    this.sixImages = Array.from({ length: 6 }, (_, i) => {
      return this.block?.images?.[i]?.url ? this.block?.images?.[i] : this.placeholderImages[i];
    });
  }
}
