// Env
import { environment } from '../environments/environment';
// Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
// Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './404.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Foster Modules
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
// Locale
import localeNl from './nl';
import { registerLocaleData } from '@angular/common';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DynamicFormConfig, DynamicFormModule } from '@teamfoster/sdk/dynamic-form';
import { formControls } from './form/form.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ContactsModule } from './contacts/contacts.module';
import { FooterComponent } from './standalone/footer/footer.component';
import { TopbarComponent } from './standalone/topbar/topbar.component';
import { AuthModule } from './auth/auth.module';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { StatisticsModule } from './statistics/statistics.module';
import { SocialMediaModule } from './social-media/social-media.module';

const cookieConfig: CookieConfig = {
  analyticsCode: 'G-1YM3002BH2',
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Alles accepteren',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: false,
  },
  showSettings: true,
};

export const dynamicFormConfig: DynamicFormConfig = {
  genericInputErrorMessage: 'Het formulier is niet correct ingevuld, controleer de velden en probeer nogmaals.',
  genericServerErrorMessage:
    'Er is iets fout gegaan bij het versturen van het formulier, probeer het later nogmaals. Blijft het probleem voordoen neem dan contact met ons op.',
  formControls,
};

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    CoreModule,
    ContactsModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Foster Modules
    DictionaryNgrxModule.forRoot({}),
    MenuNgrxModule.forRoot({}),
    CookieNgrxModule.forRoot(cookieConfig),
    DynamicFormModule.forRoot(dynamicFormConfig),
    IconModule.forRoot({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    FooterComponent,
    TopbarComponent,
    AuthModule,
    StatisticsModule,
    SocialMediaModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2000, panelClass: ['app--snackbar'], hasAction: false } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
